<template>
  <icon-base :width="size + 'px'" :fill="color">
    <path class="st0" d="M137.3,1.3H62.7c-7.5,0-13.5,6-13.5,13.5v170.3c0,7.5,6,13.5,13.5,13.5h74.7c7.5,0,13.5-6,13.5-13.5V14.8  C150.9,7.4,144.8,1.3,137.3,1.3z M58.1,17.2c0-4.4,3.6-8,8-8h21.6c4.4,0,8,3.6,8,8c0,4.4-3.6,8-8,8H66.1  C61.7,25.2,58.1,21.6,58.1,17.2z M100,76.7c-8.9,0-16.2-7.2-16.2-16.2c0-8.9,7.2-16.2,16.2-16.2s16.2,7.2,16.2,16.2  C116.2,69.5,108.9,76.7,100,76.7z" />
  </icon-base>
</template>

<script>
export default {
  name: 'iconPhoneCase',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

